import './App.css';
import { useEffect, useState } from 'react'
import firebase from "firebase";
import "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyBVCVp23jYCb_7hgPcANNYNxKUWucM3Ozo",
  authDomain: "card-11463.firebaseapp.com",
  projectId: "card-11463",
  storageBucket: "card-11463.appspot.com",
  messagingSenderId: "756040534731",
  appId: "1:756040534731:web:3544789592b6319d5fe94e",
  measurementId: "G-T8K1F544LN"
};

if (firebase.apps.length == 0) {
  firebase.initializeApp(firebaseConfig)
}


function App() {

  const currentPathname = window.location.pathname;
  const macka = 'https://search.google.com/local/writereview?placeid=ChIJwWNJx3W3yhQRD90Wk46I6Uo';
  const kalamis = 'https://search.google.com/local/writereview?placeid=ChIJvdd8G364yhQRWa2LeeCkt6c';
  const saskinbakkal = 'https://search.google.com/local/writereview?placeid=ChIJqWNDSuPGyhQR5u4pHZ5D1I4';
  const galataport = 'https://search.google.com/local/writereview?placeid=ChIJqWNDSuPGyhQR5u4pHZ5D1I4';
  const userLanguage = navigator.language || navigator.userLanguage;
  const [message, setMessage] = useState("Loading")
  const [placeName, setPlaceName] = useState(0)

  const locationData = {
    //maçka
    '/m1a3c5ka711b': { locName: 'macka', cardID: 'm1a3c5ka711b', link: macka, name:"Maçka" },
    '/m2a3c5ka721b': { locName: 'macka', cardID: 'm2a3c5ka721b', link: macka, name:"Maçka" },
    '/m3a3c5ka731b': { locName: 'macka', cardID: 'm3a3c5ka731b', link: macka, name:"Maçka"},
    '/m4a3c5ka741b': { locName: 'macka', cardID: 'm4a3c5ka741b', link: macka, name:"Maçka" },
    '/m5a3c5ka751b': { locName: 'macka', cardID: 'm5a3c5ka751b', link: macka, name:"Maçka" },
    '/m6a3c5ka761b': { locName: 'macka', cardID: 'm6a3c5ka761b', link: macka, name:"Maçka"},
    '/m7a3c5ka771b': { locName: 'macka', cardID: 'm7a3c5ka771b', link: macka, name:"Maçka"},
    //kalamış
    '/k1a1l4ami501g': { locName: 'kalamis', cardID: 'k1a1l4ami501g', link: kalamis, name:"Kalamış" },
    '/k2a1l4ami502g': { locName: 'kalamis', cardID: 'k2a1l4ami502g', link: kalamis, name:"Kalamış" },
    '/k3a1l4ami503g': { locName: 'kalamis', cardID: 'k3a1l4ami503g', link: kalamis, name:"Kalamış"},
    '/k4a1l4ami504g': { locName: 'kalamis', cardID: 'k4a1l4ami504g', link: kalamis, name:"Kalamış" },
    '/k5a1l4ami505g': { locName: 'kalamis', cardID: 'k5a1l4ami505g', link: kalamis, name:"Kalamış" },
    '/k6a1l4ami506g': { locName: 'kalamis', cardID: 'k6a1l4ami506g', link: kalamis, name:"Kalamış"},
    //şaşkınbakkal
    '/s1a8s0kin291m': { locName: 'saskinbakkal', cardID: 's1a8s0kin291m', link: saskinbakkal, name:"Şaşkınbakkal" },
    '/s2a8s0kin292m': { locName: 'saskinbakkal', cardID: 's2a8s0kin292m', link: saskinbakkal, name:"Şaşkınbakkal" },
    '/s3a8s0kin293m': { locName: 'saskinbakkal', cardID: 's3a8s0kin293m', link: saskinbakkal, name:"Şaşkınbakkal" },
    '/s4a8s0kin294m': { locName: 'saskinbakkal', cardID: 's4a8s0kin294m', link: saskinbakkal, name:"Şaşkınbakkal" },
    '/s5a8s0kin295m': { locName: 'saskinbakkal', cardID: 's5a8s0kin295m', link: saskinbakkal, name:"Şaşkınbakkal" },
    '/s6a8s0kin296m': { locName: 'saskinbakkal', cardID: 's6a8s0kin296m', link: saskinbakkal, name:"Şaşkınbakkal" },
    //galataport
    '/g1a8l0a2t291a': { locName: 'galataport', cardID: 'g1a8l0a2t291a', link: galataport, name:"Galataport" },
    '/g2a8l0a2t292a': { locName: 'galataport', cardID: 'g2a8l0a2t292a', link: galataport, name:"Galataport" },
    '/g3a8l0a2t293a': { locName: 'galataport', cardID: 'g3a8l0a2t293a', link: galataport, name:"Galataport" },
    '/g4a8l0a2t294a': { locName: 'galataport', cardID: 'g4a8l0a2t294a', link: galataport, name:"Galataport" },
    '/g5a8l0a2t295a': { locName: 'galataport', cardID: 'g5a8l0a2t295a', link: galataport, name:"Galataport" },
    '/g6a8l0a2t296a': { locName: 'galataport', cardID: 'g6a8l0a2t296a', link: galataport, name:"Galataport" },
  };
  const currentData = locationData[currentPathname]
  
  useEffect(() => {
    
   OpenUrL()

   if(userLanguage.toLowerCase().includes("tr")){
     setMessage("Google hesabı yükleniyor..")
   } else {
    setMessage("Google account loading..")
   }

  }, []); 

   async function OpenUrL(){
    
    try {
     
      if(currentData){
        setPlaceName(currentData?.name)
        writeProduct({locName:currentData?.locName, cardID:currentData?.cardID, link:currentData?.link})
      }

    } catch (error) {
      
    }
  
   }

   function writeProduct(db){
    
      try {
           fetch('https://us-central1-card-11463.cloudfunctions.net/process/sender', {
                      method: 'POST',
                      headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                      },
                      body: JSON.stringify({
                        "locName":db?.locName,
                        "cardID":db?.cardID
                      })
                    }).then((a) => {
                      if(a?.status < 400){
                        window.location.href = db?.link
                      } else {
                        window.location.href = db?.link
                      }
                   }
                    )

      } catch (error) {
        
      }

                  
   }

   return (
    <div className="App">
      <noscript>Günaydın Google Review Welcome</noscript>
      <div style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <img className="img" src="./gunaydin.png" alt="Resim Yüklenemedi" />
        {placeName != 0 &&
        <div style={{marginBottom:5, marginTop:30, alignSelf: 'center', marginLeft:5, fontSize:15, fontWeight: 400, color: 'white' }} id="loading">
          {placeName}
        </div>}
        <div style={{ alignSelf: 'center', marginLeft:10, fontWeight: 600, color: 'white' }} id="loading">
          {message}
        </div>
      </div>
    </div>
  );
}

export default App;
